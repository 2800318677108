import { useCallback, useRef } from 'react';

export function useEventCallback<T extends (...args: any[]) => any>(
  callback: T | undefined,
): T {
  const callbackRef = useRef<T | undefined>(callback);
  callbackRef.current = callback;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useCallback(((...args) => callbackRef.current?.(...args)) as T, []);
}
